.componentBase,
.componentABase {
  position: relative;
  z-index: 0;
  display: inline-flex;
  vertical-align: top;
  justify-content: center;
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-base);
  line-height: var(--line-height-text);
  color: var(--cta-color);
  background-color: var(--cta-background-color);
  border-radius: var(--size-30);
  overflow: hidden;

  & > .inner {
    position: relative;
    z-index: 2;
    height: 46px;
    min-width: 46px;

    @media (--viewport-lg) {
      height: var(--size-40);
      min-width: var(--size-40);
    }
  }

  &:hover::before,
  &:focus::before,
  &:active::before {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    content: '';
    background-color: var(--cta-color);
  }

  &:hover::before,
  &:focus::before {
    opacity: 0.1;
  }

  &:active::before {
    opacity: 0.2;
  }
}

.component,
.componentPrimary,
.componentSecondary {
  padding: 0 24px;

  @media (--viewport-lg) {
    padding: 0 var(--size-30);
  }

  &:hover > .inner,
  &:focus > .inner {
    transform: rotateX(360deg) translateZ(20px);
  }
}

.componentPrimary {
  color: var(--color-green-deep);
  background-color: var(--color-green-lime);

  &:hover::before,
  &:focus::before,
  &:active::before {
    background-color: var(--color-green-deep);
  }
}

.componentSecondary {
  color: var(--color-white);
  background-color: var(--color-green-deep);

  &:hover::before,
  &:focus::before,
  &:active::before {
    background-color: var(--color-white);
  }
}

.componentIcon,
.componentIconSmall {
  align-items: center;

  &:hover > .inner,
  &:focus > .inner {
    transform: rotateY(360deg) translateZ(20px);
  }
}

.componentIcon {
  & > .inner {
    width: var(--size-30);
    min-width: var(--size-30);
    height: var(--size-30);

    @media (--viewport-lg) {
      width: var(--size-40);
      min-width: var(--size-40);
      height: var(--size-40);
    }
  }
}

.inner {
  --visually-correction-button-font: 1px;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: var(--size-10);
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transform: rotateY(0deg) translateZ(20px);
  transition: transform 250ms var(--ease-in-out-circ);
  padding-top: var(--visually-correction-button-font);

  & > * {
    @supports not (gap: var(--size-10)) {
      margin-right: var(--size-10);
    }
  }
}

.icon {
  & > * {
    width: 100%;
    height: 100%;
  }
}

.iconSmall {
  & > * {
    width: 12px;
    height: 12px;
  }
}

.expandableContent {
  display: inline-flex;
  align-items: center;

  & > .icon {
    margin: 0 14px;
  }
}

.expandableTextContent {
  line-height: 1;
  text-align: left;
  white-space: pre;
  transition:
    width var(--duration-150) var(--ease-out-quad),
    transform var(--duration-150) var(--ease-out-quad),
    opacity var(--duration-150) var(--ease-out-quad);
}

.componentBase {
  line-height: 1;
  font-weight: var(--font-weight-bold);
}

.componentXsRegular {
  font-size: var(--font-size-16);
  font-size: var(--font-size-16-25);
  font-weight: var(--font-weight-base);
}

.componentXs {
  font-size: var(--font-size-16);
  font-size: var(--font-size-16-25);
}

.componentSm {
  font-size: var(--font-size-20);
  font-size: var(--font-size-20-30);
}

.componentMd {
  font-size: var(--font-size-20);
  font-size: var(--font-size-20-30);
}

.componentLg {
  font-size: var(--font-size-24);
  font-size: var(--font-size-24-40);
}

.componentXl {
  font-size: var(--font-size-30);
  font-size: var(--font-size-30-60);
}

.componentXxl {
  font-size: var(--font-size-34);
  font-size: var(--font-size-34-80);
}

:root {
  --ease-out-back-in: cubic-bezier(0.23, 1.76, 0.58, 0.87);
  --ease-out-expo: cubic-bezier(0.16, 1, 0.3, 1);
  --ease-out-quad: cubic-bezier(0.5, 1, 0.89, 1);
  --ease-in-out-circ: cubic-bezier(0.85, 0, 0.15, 1);

  --duration-50: 0.05s;
  --duration-150: 0.15s;
  --duration-250: 0.25s;
  --duration-400: 0.4s;
  --duration-600: 0.6s;
  --duration-1000: 1s;
  --duration-1500: 1.5s;
  --duration-2000: 2s;
  --duration-3000: 3s;
}
